const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 申请退票
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const refund = (data) => {
  pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/white/order/refund',
    urlSuffix:'/buyer/dom/manage/refund',
    data: data,
    enable_error_alert: false // 临时隐藏
  }
  return __request(pParameter)
};
module.exports = refund;
