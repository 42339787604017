//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 订单查询
const detail = require(`@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail`);
const refund = require(`@/lib/data-service/flight/flight_domestic_buyer_white_order_refund`);
  export default {
    data () {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          },
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                picker.$emit('pick', new Date());
              }
            }
          ]
        },
        detailData: null,
        orderNo: '',
        orderStatus: '',
        beginDate: '',
        orderStatusText: '',
        flightType: '1',
        passengers: [],
        changeType: 1,
        dishonourId: [],
        cabinCode: '',
        remark: ''
      }
    },
    methods: {
      // GetdetailData () {
      //   detail({orderNo: this.orderNo}).then( res => {
      //     console.log(res)
      //     this.detailData = res.detail
      //     this.flightType = res.detail.flightType
      //     this.$forceUpdate();
      //   })
      // }
      getsegments(id,inputType) {
        inputType.inputC = !inputType.inputC
        console.log(id,inputType)
        this.$forceUpdate();
      },
      getName(item) {
        console.log(item)
        item.inputC = !item.inputC
        for (let i = 0; i < item.segments.length; i++) {
          if(item.segments[i].status === '1' || item.segments[i].status === '2') {
            item.segments[i].inputC = item.inputC
            this.$forceUpdate();
          }
        }
      },
      getDishonour () {
        this.dishonourId = []
        for (let i = 0; i < this.passengers.length; i++) {
          for (let j = 0; j < this.passengers[i].segments.length; j++) {
            if(this.passengers[i].segments[j].inputC === true) {
              this.dishonourId.push(this.passengers[i].segments[j].airlineId)
            }
          }
        }
        if(this.dishonourId.length === 0) {
          return this.$message({
            message: "请选择需要改签的乘客",
            type: "error"
          });
        }
        let lineId = this.dishonourId.join()
        console.log(this.dishonourId)
        let params = {
          lineId,
          orderNo: this.orderNo,
          refundType: '1',
          remark: this.remark
        }
        console.log(params)
        refund(params).then(res => {
          console.log(res)
          this.$router.go(-1);
        }).catch(err => {
          return this.$message({
            message: err,
            type: "error"
          });
        })
      }
    },
    created () {
      if (this.$route.query.ordersNo) {
        this.orderNo = this.$route.query.ordersNo
        this.orderStatus = this.$route.query.orderStatus
        this.orderStatusText = this.$route.query.orderStatusText
        detail({orderNo: this.orderNo}).then( res => {
          console.log(res)
          this.detailData = res.detail
          this.flightType = res.detail.flightType
          this.passengers = res.detail.passengers
          for (let i = 0; i < this.passengers.length; i++) {
            this.passengers[i].inputC = false
            for (let j = 0; j < this.passengers[i].segments.length; j++) {
              this.passengers[i].segments[j].inputC = false
            }
          }
          console.log(1111)
          this.$forceUpdate();
        })
      }
    }
  }
